import { StateDevice, DeviceActions, DeviceActionType } from "./device";
const DEBUG_MODE_KEY = "debugMode";

export const getDebugModeFromStore = () => {
  return window.localStorage.getItem(DEBUG_MODE_KEY) === "true";
};

export const setDebugModeToStore = (value: boolean) => {
  return window.localStorage.setItem(DEBUG_MODE_KEY, value.toString());
};

const deviceReducer = (state: StateDevice, action: DeviceActions) => {
  const { type, payload } = action;
  switch (type) {
    case DeviceActionType.setDataConnect:
      return {
        ...state,
        connect: payload,
        device: payload.device,
      };
    case DeviceActionType.setIsConnected:
      return {
        ...state,
        isConnected: payload,
      };
    case DeviceActionType.setConfigDevice:
      return {
        ...state,
        oldConfig: payload,
        configDevice: payload,
      };
    case DeviceActionType.setData:
      const data = { ...state.data };
      data[payload.name] = payload.data;
      return {
        ...state,
        data,
      };
    case DeviceActionType.setWifiConnectionStatus:
      return {
        ...state,
        wifiStatus: {
          message: payload.message,
          status: payload.status,
        },
      };
    case DeviceActionType.setWifiData:
      return {
        ...state,
        wifiData: payload,
      };
    case DeviceActionType.setDebugMode:
      setDebugModeToStore(payload);
      return {
        ...state,
        debugMode: payload,
      };
    case DeviceActionType.resetDevice:
      return {
        ...payload,
      };
    case DeviceActionType.setWifiDataIsConnect:
      const wifiIsConnect = [...state.wifiData].find(
        (item) => item.name === payload
      );
      if (wifiIsConnect) {
        return {
          ...state,
          wifi: wifiIsConnect,
        };
      }
      return state;
    case DeviceActionType.setStatusDevice:
      return {
        ...state,
        deviceStatus: payload,
      };
    case DeviceActionType.changeConfig:
      const dataConfig = { ...state.configDevice }[payload.key];
      const newDataConfig = { ...dataConfig, ...payload.data };
      return {
        ...state,
        configDevice: { ...state.configDevice, [payload.key]: newDataConfig },
      };
    case DeviceActionType.setTabIndex:
      return {
        ...state,
        tabIndex: payload,
      };
    case DeviceActionType.setIsConnectBluetooth:
      return {
        ...state,
        isConnectBluetooth: payload,
      };
    default:
      return state;
  }
};

export { deviceReducer };
