import React, { useEffect } from "react";
import "./assets/style/global.scss";
import { RouterConnect } from "./router";
import {useDeviceDispatch } from "./hooks";
import {getDebugModeFromStore} from "./state/Device/deviceReducer";

const App = () => {
  const { setDebugMode } = useDeviceDispatch();
  useEffect(() => {
    setDebugMode(getDebugModeFromStore());
  }, []);

  return (
    <>
      <RouterConnect />
    </>
  );
};

export { App };
