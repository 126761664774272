import { FC, PropsWithChildren } from "react";
import { IconOval } from "components"; 
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";

type ModalInfoProps = PropsWithChildren<{
  isOpen: boolean;
  onClose: () => void;
  activeOnClick?: () => void;
  title?: string;
  textClose: string;
  textActive: string;
  isLoading?: boolean
}>;

const ModalInfo: FC<ModalInfoProps> = ({
  isOpen,
  onClose,
  activeOnClick,
  isLoading = false,
  title,
  children,
  textClose,
  textActive,
}) => {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader mt={"15px"}>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{children}</ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={activeOnClick}>
              {isLoading ? <IconOval/> : textActive}
            </Button>
            <Button mr={3} onClick={onClose}>
              {textClose}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export { ModalInfo };
