import React, { FC, useState } from "react";
import {
  Button,
  Input,
  Flex,
  Text,
  Checkbox,
  useDisclosure,
  TabPanel,
  Tab,
  FormLabel,
} from "@chakra-ui/react";
import { getServerCharacteristic, setWifi } from "service/btService";
import { ModalInfo, IconOval } from "components";
import { useDeviceState, useDeviceDispatch } from "hooks";
import { WIFI_ENUM, wifiConfigService } from "service/Service";
import { WifiBox } from "../WifiBox";
import {
  getIsValidForm,
  getIsCloseWifi,
  getIsValidInputPassword,
  createSerialProcessor,
  getConfigDevice,
} from "utils";

type WifiConfigProps = {};

const WifiConfigPanel: FC<WifiConfigProps> = () => {
  const [value, setValue] = useState("");
  const [password, setPassword] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const data = useDeviceState();
  const {
    connect,
    wifiStatus: { message, status },
    wifiData,
    configDevice,
  } = data;
  const { setWifiConnectionStatus, setWifiDataIsConnect, setConfigDevice, setTabIndex } =
    useDeviceDispatch();

  const handleOnClickSaveAnyway = async () => {
    if (connect) {
      const status = await setWifi(connect, value, password, true);
      setWifiConnectionStatus(status);
      onClose();
    }
  };

  const handelSubmit = async (event: any) => {
    event.preventDefault();
    const isWifi = getIsValidForm(wifiData, value, isChecked, password);
    if (isWifi) {
      return onOpen();
    }
    if (connect) {
      setIsLoading(true);
      const config = await getConfigDevice(configDevice, connect);
      const status = await setWifi(connect, value, password);

      setIsLoading(false);
      setConfigDevice(config);
      if (status === WIFI_ENUM.WIFI_FAIL_CONNECTION) {
        onOpen();
      }
      if (status === WIFI_ENUM.WIFI_CONNECTED) {
        setWifiDataIsConnect(value);
        setTabIndex(1);
      }
      setWifiConnectionStatus(status);
    }
  };

  return (
    <Flex justifyContent={"center"} alignItems={"center"}>
      <form onSubmit={handelSubmit}>
        <Text fontSize="4xl">Connect Wifi</Text>
        <WifiBox setName={setValue} hiddenNetwork={false} />
        <Flex
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={"3"}
        >
          <Checkbox
            fontSize={"14px"}
            onChange={(event) => setIsChecked(event.target.checked)}
          >
            Hidden Network
          </Checkbox>
          <FormLabel margin={"0"} width={"100%"}>
            SSID
            <Input
              type={"text"}
              value={value}
              maxLength={63}
              onChange={(event) => setValue(event.target.value)}
            />
          </FormLabel>
          <FormLabel margin={"0"} width={"100%"}>
            Password
            <Input
              disabled={!getIsCloseWifi(wifiData, value)}
              maxLength={32}
              value={password}
              name={"password"}
              type={"password"}
              onChange={(event) => setPassword(event.target.value)}
            />
          </FormLabel>
          <Button
            disabled={
              value.length <= 5 ||
              !getIsValidInputPassword(wifiData, value, password)
            }
            w={"100%"}
            colorScheme={"blue"}
            type={"submit"}
          >
            {isLoading ? <IconOval /> : "Connect"}
          </Button>
          <Text fontWeight={"bold"}>{message}</Text>
        </Flex>
      </form>
      <ModalInfo
        activeOnClick={handleOnClickSaveAnyway}
        isOpen={isOpen}
        onClose={onClose}
        textActive="Save anyway"
        textClose="Cancel"
        title={
          status === WIFI_ENUM.WIFI_FAIL_CONNECTION
            ? "Warning! Unit failed to connect to chooser network. Save credentials anyway? Unit will use them after self-reset"
            : "Warning! You try to connect to network that is not available in current area. Save credentials anyway? Unit will use them after self-reset."
        }
      />
    </Flex>
  );
};

export const WifiConfig = {
  TabPanel: ({ ...props }) => (
    <TabPanel {...props}>
      <WifiConfigPanel />
    </TabPanel>
  ),
  TabHeader: (props: any) => {
    const { connect, data } = useDeviceState();
    const { setData, setWifiData } = useDeviceDispatch();
    const { setIsRequest } = props;
    return (
      <Tab
        borderBottomWidth={"2px"}
        borderColor={"primary.100"}
        bg={"primary.600"}
        color={"white"}
        _selected={{ color: "white" }}
        onClick={async () => {
          if (connect) {
            if (!data.hasOwnProperty("WIFI_CONFIG")) {
              setIsRequest(false);
            }
            const res = await createSerialProcessor(
              getServerCharacteristic.bind(null, connect, wifiConfigService),
              0
            );

            setIsRequest(true);
            setData(res);
            // @ts-ignore

            setWifiData(res.data.WIFI_LIST);
          }
        }}
      >
        Wi-Fi config
      </Tab>
    );
  },
};
