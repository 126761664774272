import { WIFI_ENUM } from "service/Service";

const getMessageWifiStatus = (status: WIFI_ENUM) => {
	switch (status) {
		case WIFI_ENUM.WIFI_NOT_CONNECTED:
			return 'Wifi not Connected';
		case WIFI_ENUM.WIFI_CONN_IN_PROGRESS:
			return 'Wifi conn in Progress';
		case WIFI_ENUM.WIFI_FAIL_CONNECTION:
			return 'Wifi fail Connected';
		case WIFI_ENUM.WIFI_CONNECTED:
			return 'Wifi Connected';
		default:
			return null;
	}
};

export { getMessageWifiStatus }
