import { FC } from "react";
import { Button, Flex } from "@chakra-ui/react";
import {
  WifiSvgConnectionQualityWithoutPassword,
  WifiSvgConnectionQualityWithPassword,
} from "assets/icons/global";
import { useDeviceState } from "hooks";

type WifiBoxProps = {
  disabled?: boolean;
  setName?: (name: string) => void;
  hiddenNetwork: boolean;
};

type WifiContainerProps = {
  close: boolean;
  connection: number;
};

const WifiContainer: FC<WifiContainerProps> = ({ close, connection }) => {
  return (
    <>
      {close ? (
        <WifiSvgConnectionQualityWithPassword quality={connection} />
      ) : (
        <WifiSvgConnectionQualityWithoutPassword quality={connection} />
      )}
    </>
  );
};

const WifiBox: FC<WifiBoxProps> = ({
  disabled = false,
  setName = () => {},
  hiddenNetwork,
}) => {
  const { wifiData } = useDeviceState();
  return (
    <Flex maxWidth={"283px"} width={"100%"} flexDirection={"column"} gap={"2"} border={"black"} mb={"5"} >
      {wifiData &&
        wifiData
          .filter((item) => hiddenNetwork || item.name !== "Hidden Network")
          .map((item, index) => {
            const { name, connection, close } = item;
            return (
              <Button
                width={"100%"}
                disabled={disabled}
                display={"flex"}
                gap={"2"}
                type={"button"}
                key={index}
                onClick={() => setName(name)}
              >
                {name}
                <WifiContainer connection={connection} close={close} />
              </Button>
            );
          })}
    </Flex>
  );
};

export { WifiBox };
