import { FC } from "react";
import {
  MdOutlineSignalWifiStatusbar4Bar,
  MdOutlineNetworkWifi,
  MdSignalWifiStatusbar2Bar,
  MdSignalWifiStatusbar1Bar,
} from "react-icons/md";

type WifiSvgConnectionQualityProps = {
  quality: number;
  size?: string;
};

const WifiSvgConnectionQualityWithoutPassword: FC<
  WifiSvgConnectionQualityProps
> = ({ quality, size = "16px" }) => {
  if (quality >= -50) {
    return <MdOutlineSignalWifiStatusbar4Bar size={size} color="blue" />;
  } else if (quality < -50 && quality > -60) {
    return <MdOutlineNetworkWifi size={size} color="blue" />;
  } else if (quality <= -60 && quality > -70) {
    return <MdSignalWifiStatusbar2Bar size={size} color="blue" />;
  } else if (quality <= -70) {
    return <MdSignalWifiStatusbar1Bar size={size} color="blue" />;
  } else {
    return null;
  }
};

export { WifiSvgConnectionQualityWithoutPassword };
