import {getConfigDevice} from "utils";
import {btService, putUnitConfig, writeToConfig} from "service/btService";
import {useDeviceDispatch} from "hooks/useDeviceDispatch";
import {useDeviceState} from "hooks/useDeviceState";
import {useEffect, useState} from "react";

export const useDisconnection = (): () => void => {
    const { setIsConnected, resetDevice, setIsConnectBluetooth } = useDeviceDispatch();
    const { data, connect, device, configDevice, oldConfig } = useDeviceState();
    const [disconnect, setDisconnection] = useState(false);
    const { UNIT_ID } = data;
    useEffect(() => {
        if (connect && disconnect) {
            (async () => {
                const config = await getConfigDevice(configDevice, connect);
                const { sampling, sending } = config["general"];
                await putUnitConfig(sampling, UNIT_ID, "general.sampling");
                await putUnitConfig(sending, UNIT_ID, "general.sending");
                if (JSON.stringify(config) !== JSON.stringify(oldConfig) && configDevice) {
                    await writeToConfig(connect, config);
                }
                btService.disconnect(device);
                setIsConnected(false);
                setIsConnectBluetooth(false);
                resetDevice();
            })();
        }
    }, [disconnect, connect]);
    return () => {
        setDisconnection(true);
    }
}