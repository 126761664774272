import { FC, ReactElement, useEffect } from "react";
import { MdOutlineCloudDownload } from "react-icons/md";
import { FaRegClock } from "react-icons/fa";
import { IoSettingsOutline } from "react-icons/io5";
import { GiWifiRouter } from "react-icons/gi";
import { IconType } from "react-icons";
import { Box, Tab, TabPanel, Text, Flex } from "@chakra-ui/react";
import { IconRadGreen } from "components";
import { useDeviceDispatch, useDeviceState } from "hooks";
import { getServerCharacteristic } from "service/btService";
import {
  ConnectionStatus,
  getServiceData,
  unitConnectivityService,
  LAN_ENUM,
} from "service/Service";
import { createSerialProcessor } from "utils";

type UnitConnectivityStateProps = {
  setIsRequest: (value: boolean) => void;
};

type TextStatusProps = {
  status: number;
};

type StatusContainerProps = {
  icon: ReactElement<IconType>;
  text: string;
  value: number;
  isLan?: boolean;
};

const TextStatusConnect: FC<TextStatusProps> = ({ status }) => {
  switch (status) {
    case ConnectionStatus.established:
      return <Text fontWeight={"bold"}>Connection established</Text>;
    case ConnectionStatus.failed:
      return <Text fontWeight={"bold"}>Connection failed</Text>;
    case ConnectionStatus.inProgress:
      return <Text fontWeight={"bold"}>Connection in progress</Text>;
    default:
      return null;
  }
};

const TextStatusConnectLan: FC<TextStatusProps> = ({ status }) => {
  switch (status) {
    case LAN_ENUM.WIFI_CONNECTED:
      return (
        <Text fontWeight={"bold"}>Unit connected to router with Wi-Fi</Text>
      );
    case LAN_ENUM.WIFI_NOT_CONNECTED:
      return (
        <Text fontWeight={"bold"}>Unit not connected to router with Wi-Fi</Text>
      );
    case LAN_ENUM.LAN_CONNECTED:
      return (
        <Text fontWeight={"bold"}>
          Unit connected to router with Ethernet cable
        </Text>
      );
    case LAN_ENUM.LAN_FAIL_CONNECTION:
      return (
        <Text fontWeight={"bold"}>
          Unit not connected to router with Ethernet cable
        </Text>
      );
    default:
      return null;
  }
};

const StatusContainer: FC<StatusContainerProps> = ({
  icon,
  text,
  value,
  isLan = false,
}) => {
  return (
    <Flex
      justifyContent={"flex-start"}
      alignContent={"center"}
      flexWrap={"wrap"}
      gap={"5"}
      mb={"10px"}
    >
      {icon}
      <Flex
        gap={"5"}
        flexWrap={"wrap"}
        justifyContent={"flex-start"}
        alignItems={"center"}
      >
        <Text>{text}</Text>
        {isLan ? (
          <TextStatusConnectLan status={value} />
        ) : (
          <TextStatusConnect status={value} />
        )}
      </Flex>
    </Flex>
  );
};

const UnitConnectivityStateContainer: FC<UnitConnectivityStateProps> = (
  props
) => {
  const { data, connect } = useDeviceState();
  const serviceData = getServiceData(data, unitConnectivityService.name);
  const { setData } = useDeviceDispatch();
  const { setIsRequest } = props;
  const {
    UNIT_LAN_CONNECTION_STATUS,
    UNIT_INTER_CONNECTION_STATUS,
    UNIT_NTP_SERVER_CONNECTION_STATUS,
    UNIT_CENTRAL_SERVER_CONNECTION_STATUS,
    UNIT_CONFIG_SERVER_CONNECTION_STATUS,
  } = serviceData;

  useEffect(() => {
    (async () => {
      if (connect) {
        if (!data.hasOwnProperty("Unit Connectivity")) {
          setIsRequest(false);
        }
        const res = await createSerialProcessor(
          getServerCharacteristic.bind(null, connect, unitConnectivityService),
          0
        );
        setIsRequest(true);
        setData(res);
      }
    })();
  }, []);

  return (
    <Box textAlign={"center"}>
      <Box maxW={"800px"} display={"inline-block"}>
        <StatusContainer
          icon={<GiWifiRouter color="blue" size={"100px"} />}
          text="Unitionnection with router"
          value={UNIT_LAN_CONNECTION_STATUS}
          isLan={true}
        />
        <StatusContainer
          icon={<MdOutlineCloudDownload size={"100px"} color="gray" />}
          text="Unit connection with Internet"
          value={UNIT_INTER_CONNECTION_STATUS}
        />
        <StatusContainer
          icon={<FaRegClock size={"100px"} color="purple" />}
          text="Unit connection with NTP server"
          value={UNIT_NTP_SERVER_CONNECTION_STATUS}
        />
        <StatusContainer
          icon={<IconRadGreen />}
          text="Unit connection with RadGreen central"
          value={UNIT_CENTRAL_SERVER_CONNECTION_STATUS}
        />
        <StatusContainer
          icon={<IoSettingsOutline size={"100px"} />}
          text="Unit connection with configuration server"
          value={UNIT_CONFIG_SERVER_CONNECTION_STATUS}
        />
      </Box>
    </Box>
  );
};

export const UnitConnectivityState = {
  TabPanel: ({ ...props }) => {
    const { setIsRequest, ...data } = props;
    return (
      <TabPanel {...data}>
        <UnitConnectivityStateContainer setIsRequest={setIsRequest} />
      </TabPanel>
    );
  },
  TabHeader: (props: any) => {
    return (
      <Tab
        borderBottomWidth={"2px"}
        borderColor={"primary.100"}
        bg={"primary.600"}
        color={"white"}
        _selected={{ color: "white" }}
        marginLeft={"2px"}
      >
        {unitConnectivityService.name}
      </Tab>
    );
  },
};
