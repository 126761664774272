import { readUnitConfig } from "service/btService";
import { createSerialProcessor } from "utils";

const getConfigDevice = async (config: any, connect: BluetoothRemoteGATTServer) => {
  if (config){
    return config;
  }
  console.log("???");
  return createSerialProcessor(readUnitConfig.bind(null,connect), 0);
};

export { getConfigDevice };
