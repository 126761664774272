import React, { FC } from "react";
import { BiWifi, BiWifi0, BiWifi1, BiWifi2 } from "react-icons/bi";

type WifiSvgConnectionQualityProps = {
  quality: number;
  size?: string;
};

const WifiSvgConnectionQuality: FC<
  WifiSvgConnectionQualityProps
> = ({ quality, size = "16px" }) => {
  if (quality >= -50) {
    return <BiWifi size={size} color="blue" />;
  } else if (quality < -50 && quality > -60) {
    return <BiWifi2 size={size} color="blue" />;
  } else if (quality <= -60 && quality > -70) {
    return <BiWifi1 size={size} color="blue" />;
  } else if (quality <= -70) {
    return <BiWifi0 size={size} color="blue" />;
  } else {
    return null;
  }
};

export { WifiSvgConnectionQuality };
