import React, { FC, PropsWithChildren, useReducer } from "react";
import { deviceReducer, getDebugModeFromStore } from "./deviceReducer";
import { DeviceContext } from "./DeviceContext";
import { DeviceDispatch } from "./DeviceDispatch";
import { DeviceActionType, StateDevice } from "./device";
import { getMessageWifiStatus, getWifiData } from "utils";

type DeviceContextProviderProps = PropsWithChildren<{}>;
export const initialState = {
  debugMode: getDebugModeFromStore(),
  data: {},
  isConnected: false,
  isConnectBluetooth: false,
  device: undefined,
  connect: undefined,
  namesWifi: [],
  wifiStatus: {
    message: "",
    status: 0,
  },
  wifi: {
    close: false,
    connection: 0,
    name: "",
  },
  wifiData: [],
  deviceStatus: 0,
  configDevice: undefined,
  oldConfig: null,
  tabIndex: 0,
};

const DeviceContextProvider: FC<DeviceContextProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(deviceReducer, initialState);
  const context: StateDevice = state;
  const setIsConnected = (isConnected: boolean) => {
    dispatch({
      type: DeviceActionType.setIsConnected,
      payload: isConnected,
    });
  };
  const setConfigDevice = (device: any) => {
    dispatch({
      type: DeviceActionType.setConfigDevice,
      payload: device,
    });
  };
  const setData = (data: any) => {
    dispatch({
      type: DeviceActionType.setData,
      payload: data,
    });
  };
  const setWifiConnectionStatus = (status: number) => {
    dispatch({
      type: DeviceActionType.setWifiConnectionStatus,
      payload: {
        message: getMessageWifiStatus(status) || "",
        status,
      },
    });
  };
  const setWifiData = (string: string) => {
    dispatch({
      type: DeviceActionType.setWifiData,
      payload: getWifiData(string),
    });
  };

  const setDebugMode = (value: boolean) => {
    dispatch({
      type: DeviceActionType.setDebugMode,
      payload: value,
    });
  };

  const setDataConnect = (connect: BluetoothRemoteGATTServer) => {
    dispatch({
      type: DeviceActionType.setDataConnect,
      payload: connect,
    });
  };

  const resetDevice = () => {
    dispatch({
      type: DeviceActionType.resetDevice,
      payload: initialState,
    });
  };

  const setWifiDataIsConnect = (value: string) => {
    dispatch({
      type: DeviceActionType.setWifiDataIsConnect,
      payload: value,
    });
  };

  const setStatusDevice = (value: number) => {
    dispatch({
      type: DeviceActionType.setStatusDevice,
      payload: value,
    });
  };

  const changeConfig = (value: any) => {
    dispatch({
      type: DeviceActionType.changeConfig,
      payload: value,
    });
  };

  const setTabIndex = (index: number) => {
    dispatch({
      type: DeviceActionType.setTabIndex,
      payload: index,
    });
  };

  const setIsConnectBluetooth = (value: boolean) => {
    dispatch({
      type: DeviceActionType.setIsConnectBluetooth,
      payload: value,
    });
  };

  return (
    <DeviceContext.Provider value={context}>
      <DeviceDispatch.Provider
        value={{
          setIsConnected,
          setConfigDevice,
          setData,
          setWifiConnectionStatus,
          setWifiData,
          setDebugMode,
          setDataConnect,
          resetDevice,
          setWifiDataIsConnect,
          setStatusDevice,
          changeConfig,
          setTabIndex,
          setIsConnectBluetooth,
        }}
      >
        {children}
      </DeviceDispatch.Provider>
    </DeviceContext.Provider>
  );
};

export { DeviceContextProvider };
