const getIsValidInputName = (
  data: any,
  value: string,
  isChecked: boolean,
  password: string
) => {
  if (!isChecked) {
    return data.some(({ name }: any) => name === value);
  } else {
    return value && password;
  }
};

export { getIsValidInputName };
