import { getIsValidInputPassword } from "./getIsValidInputPassword";
import { getIsValidInputName } from "./getIsValidInputName";

const getIsValidForm = (
  data: any,
  value: string,
  isChecked: boolean,
  password: string
) => {
  return (
    !getIsValidInputName(data, value, isChecked, password) ||
    !getIsValidInputPassword(data, value, password)
  );
};

export { getIsValidForm }
