import { Tab, TabPanel } from "@chakra-ui/react";
import { useDeviceDispatch, useDeviceState } from "hooks";
import { getServerCharacteristicError } from "service/btService";
import { createSerialProcessor } from "utils";

const ErrorReportContainer = () => {
  const { data } = useDeviceState();
  const { ERROR_REPORT } = data;

  return (
    <div>
      {!ERROR_REPORT?.messenger ? "No errors" : ERROR_REPORT?.messenger}
    </div>
  );
};

export const ErrorReport = {
  TabPanel: ({ ...props }) => (
    <TabPanel {...props}>
      <ErrorReportContainer />
    </TabPanel>
  ),
  TabHeader: (props: any) => {
    const { connect, data } = useDeviceState();
    const { setData } = useDeviceDispatch();
    const { setIsRequest } = props;
    return (
      <Tab
        w={"160px"}
        borderBottomWidth={"2px"}
        borderColor={"primary.100"}
        onClick={async () => {
          if (connect) {
            if (!data.hasOwnProperty("ERROR_REPORT")) {
              setIsRequest(false);
            }
            const res = await createSerialProcessor(
              getServerCharacteristicError.bind(null, connect),
              1
            );

            setIsRequest(true);
            setData(res);
          }
        }}
      >
        Error Report
      </Tab>
    );
  },
};
