import { FC, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Heading, Flex } from "@chakra-ui/react";
import {
  btService,
  getServerCharacteristicSoftwareVersion,
  getServerCharacteristicHardwareVersion,
  getServerCharacteristicUnitId,
  getServerCharacteristic
} from "service/btService";
import { wifiConfigService } from "service/Service";
import { useDeviceDispatch, useDeviceState } from "hooks";
import { IconOval } from "components";
import { createSerialProcessor } from "utils";

type ConnectPageProps = {};

const DebugModeCheckbox: FC<{
  value: boolean,
  setDebugMode: (value: boolean) => void,
}>  = ({ value, setDebugMode }) => {
  if (process.env.NODE_ENV === "production") {
    return null;
  }
  return <>
    <label htmlFor="debug-mode">Debug mode:
      <input type="checkbox" name="debug-mode" onChange={() => setDebugMode(!value)} checked={value} />
    </label>
  </>
}

const ConnectPage: FC<ConnectPageProps> = () => {
  const navigate = useNavigate();
  const { setData, setDebugMode, setDataConnect, setWifiData, setIsConnected, setIsConnectBluetooth } = useDeviceDispatch();
  const { connect, isConnected, debugMode, data } = useDeviceState();
  const [connecting, setConnecting] = useState(false);

  const handleConnect = async () => {
    const btDevice = await btService.BLEconnect(debugMode);
    setConnecting(true);
    btDevice?.gatt?.connect().then((connect: BluetoothRemoteGATTServer) => {
      console.log("CONNECT: ", connect);
      const { connected } = connect;
      setDataConnect(connect);
      setIsConnected(connected);
      setIsConnectBluetooth(connected);
      setConnecting(false);
    });
  };
  const setServerCharacteristic = async (
    func: any,
    name: string,
    connect: BluetoothRemoteGATTServer
  ) => {
    if (!data.hasOwnProperty(name)) {
      const result = await createSerialProcessor(func.bind(null, connect), 0);
      setData(result);
    }
  };

  const process = async (connect: BluetoothRemoteGATTServer) => {
    try {
      setServerCharacteristic(
        getServerCharacteristicHardwareVersion,
        "HARDWARE_VERSION",
        connect
      );
      setServerCharacteristic(
        getServerCharacteristicSoftwareVersion,
        "SOFTWARE_VERSION",
        connect
      );
      setServerCharacteristic(
        getServerCharacteristicUnitId,
        "UNIT_ID",
        connect
      );
      const res = await createSerialProcessor(
        getServerCharacteristic.bind(null, connect, wifiConfigService),
        0
      );
      setData(res);
      setWifiData(res.data.WIFI_LIST);

      navigate("/connect", { replace: true });
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(()=>{
    if(connect){
      process(connect)
    }
  },[connect]);

  return (
    <Flex
      w={"60%"}
      m={"auto"}
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
      pt={"16"}
    >
      <Heading as={"h3"} size={"lg"} mb={"3"}>
        RadGreen
      </Heading>
      <DebugModeCheckbox value={debugMode} setDebugMode={setDebugMode} />
      <Button
          size={"lg"}
          colorScheme={isConnected ? "green": "blue"}
          type={"button"}
          disabled={isConnected}
          onClick={handleConnect}
      >
        {isConnected ? "Device Selected": "Select Device"}
        {connecting && <IconOval margin="3" />}
      </Button>
    </Flex>
  );
};

export { ConnectPage };
