import { FC } from "react";
import { Tab, TabPanel, Text, Box } from "@chakra-ui/react";
import { getServerCharacteristicDeviceInfo } from "service/btService";
import { useDeviceDispatch, useDeviceState } from "hooks";
import { createSerialProcessor } from "utils";

const UnitInfoText: FC<{ title: string; text: string }> = ({ title, text }) => {
  return (
    <Box display={"flex"} gap={"2"}>
      <Text fontWeight={"bold"} flex={"0.1"}>
        {title}:
      </Text>
      <Text flex={"1"} textAlign="left">{text}</Text>
    </Box>
  );
};

const UnitInfoContainer = () => {
  const { data } = useDeviceState();
  const { DEVICE_INFO, HARDWARE_VERSION, SOFTWARE_VERSION } = data;

  return (
    <>
      <UnitInfoText title="Device info" text={DEVICE_INFO} />
      <UnitInfoText title="Hardware version" text={HARDWARE_VERSION} />
      <UnitInfoText title="Software version" text={SOFTWARE_VERSION} />
    </>
  );
};

export const UnitInfo = {
  TabPanel: ({ ...props }) => (
    <TabPanel {...props}>
      <UnitInfoContainer />
    </TabPanel>
  ),
  TabHeader: (props: any) => {
    const { setIsRequest } = props;
    const { connect, data } = useDeviceState();
    const { setData } = useDeviceDispatch();
    return (
      <Tab
        w={"160px"}
        borderBottomWidth={"2px"}
        borderColor={"primary.100"}
        onClick={async () => {
          if (connect) {
            if (!data.hasOwnProperty("DEVICE_INFO")) {
              setIsRequest(false);
            }
            const res = await createSerialProcessor(
              getServerCharacteristicDeviceInfo.bind(null, connect),
              0
            );

            setIsRequest(true);
            setData(res);
          }
        }}
      >
        Device info
      </Tab>
    );
  },
};
