import {FC, useState} from "react";
import { Tab, TabPanel, Button, Text, Flex } from "@chakra-ui/react";
import { useDeviceState, useDeviceDispatch } from "hooks";
import { getServerCharacteristic, writeUnitConfig } from "service/btService";
import {
  unitConnectivityService,
} from "service/Service";
import { createSerialProcessor } from "utils";
import { useIsConnected } from "hooks/useIsConnected";
import { useDisconnection } from "hooks/useDisconnection";

type SoftwareUpdateProps = {};

const SoftwareUpdateText: FC<{ text: string }> = ({ text }) => {
  return (
      <Text fontWeight={"bold"}>{text}</Text>
  );
};

const SoftwareUpdateContainer: FC<SoftwareUpdateProps> = () => {
  const { connect } = useDeviceState();
  const [updateStatus, setUpdateStatus] = useState("");
  
  const isConnect = useIsConnected();
  const disconnect = useDisconnection();
    
  if (!connect) {
    return null;
  }

  return (
    <Flex
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      gap={"8px"}
    >
      {!isConnect && <SoftwareUpdateText text="Unit not connected to LAN network and/or to config server" /> }
      <SoftwareUpdateText text={updateStatus} />
      <Button
        disabled={!isConnect}
        onClick={() => {
          if (connect) {
            setUpdateStatus("Updating...");
            try {
              createSerialProcessor(writeUnitConfig.bind(null, connect, "1"), 0).then(() => {
                setUpdateStatus("Successfully Updated");
                disconnect();
              })
            } catch (e) {
              console.log(e);
              setUpdateStatus("Error, please try again later");
            }
          }}
        }
        colorScheme={"telegram"}
      >
        Software update
      </Button>
    </Flex>
  );
};

const SoftwareUpdate = {
  TabPanel: ({ ...props }) => (
    <TabPanel {...props}>
      <SoftwareUpdateContainer />
    </TabPanel>
  ),
  TabHeader: ({ ...props }) => {
    const { setIsRequest } = props;
    const { connect, data } = useDeviceState();
    const { setData } = useDeviceDispatch();
    return (
      <Tab
        onClick={async () => {
          if (connect) {
            if (!data.hasOwnProperty("Unit Connectivity")) {
              setIsRequest(false);
            }
            const res = await createSerialProcessor(
              getServerCharacteristic.bind(
                null,
                connect,
                unitConnectivityService
              ),
              0
            );

            setIsRequest(true);
            setData(res);
          }
        }}
      >
        Software update
      </Tab>
    );
  },
};

export { SoftwareUpdate };
