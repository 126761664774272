import PQueue from "p-queue";

const queue = new PQueue({concurrency: 1});

const createSerialProcessor:any = async (
  asyncProcessingCallback: any,
  priority: number
) => {
  const result = await queue.add(asyncProcessingCallback, {
    priority,
  });
  return result;
};

export { createSerialProcessor };
