import { FC, FormEvent, useState, useEffect, ChangeEvent } from "react";
import {
  Button,
  Tab,
  TabPanel,
  Input,
  Flex,
  FormLabel,
  Text,
} from "@chakra-ui/react";
import { useDeviceDispatch, useDeviceState } from "hooks";
import { getConfigDevice, getValidInput } from "utils";

type ChangeConfigurationProps = {};

const ChangeConfigurationContainer: FC<ChangeConfigurationProps> = () => {
  const { setConfigDevice, changeConfig } = useDeviceDispatch();
  const { connect, configDevice } = useDeviceState();
  const [sampling, setSampling] = useState("");
  const [sending, setSending] = useState("");
  const [isShow, setIsShow] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [isValidSample, setIsValidSample] = useState(false);
  const [isValidSend, setIsValidSend] = useState(false);

  useEffect(() => {
    if (connect) {
      (async () => {
        const config = await getConfigDevice(configDevice, connect);
        console.log("config", config);
        setConfigDevice(config);
        const { sampling, sending } = config["general"];
        setSampling(sampling);
        setSending(sending);
      })();
    }
  }, []);

  const checkIsChange = (newSending: string, newSampling: string) => {
    const { sampling, sending } = configDevice["general"];
    const isChange = newSending !== sending || sampling !== newSampling;
    setIsSave(isChange);
  };

  const handleChangeSampling = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (!Number.isNaN(+value)) {
      setSampling(value);
      checkIsChange(sending, value);
      setIsValidSample(!getValidInput(value));
    }
  };

  const handleChangeSending = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (!Number.isNaN(+value)) {
      setSending(value);
      checkIsChange(value, sampling);
      setIsValidSend(!getValidInput(value));
    }
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (connect) {
      changeConfig({
        key: "general",
        data: {
          sampling,
          sending,
        },
      });
      setIsShow(true);
      setIsSave(false);
      setTimeout(() => {
        setIsShow(false);
      }, 2000);
    }
  };
  
  return (
    <Flex justifyContent={"center"} alignItems={"center"}>
      <form onSubmit={handleSubmit}>
        <Flex flexDirection={"column"} gap={"20px"} maxWidth={"283px"}>
          <FormLabel margin={"0"} position={"relative"}>
            Sampling
            <Input
              minLength={1}
              maxLength={3}
              value={sampling}
              onChange={handleChangeSampling}
              type={"text"}
            />
            {isValidSample && (
              <Text position={"absolute"} fontSize={"10px"} color={"red"}>
                The sample must be no larger than 480 and no smaller than 1
              </Text>
            )}
          </FormLabel>
          <FormLabel margin={"0"} position={"relative"}>
            Sending
            <Input
              minLength={1}
              maxLength={3}
              value={sending}
              onChange={handleChangeSending}
              type={"text"}
            />
            {isValidSend && (
              <Text position={"absolute"} fontSize={"10px"} color={"red"}>
                The send must be no larger than 480 and no smaller than 1
              </Text>
            )}
          </FormLabel>
          <Button disabled={!isSave || isValidSample || isValidSend} type="submit" colorScheme={"blue"}>
            Save
          </Button>
          {isShow && (
            <Text fontWeight={"bold"}>
              Changes have been successfully saved
            </Text>
          )}
        </Flex>
      </form>
    </Flex>
  );
};

const ChangeConfiguration = {
  TabPanel: ({ ...props }) => {
    return (
      <TabPanel {...props}>
        <ChangeConfigurationContainer />
      </TabPanel>
    );
  },
  TabHeader: ({ ...props }) => {
    return (
      <Tab
        borderBottomWidth={"2px"}
        borderColor={"primary.100"}
        bg={"primary.600"}
        color={"white"}
        _selected={{ color: "white" }}
        marginLeft={"2px"}
        {...props}
      >
        Change configuration
      </Tab>
    );
  },
};

export { ChangeConfiguration };
