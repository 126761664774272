import { FC } from "react";
import {
  MdOutlineSignalWifi4BarLock,
  MdSignalWifi3BarLock,
  MdSignalWifi2BarLock,
  MdSignalWifi1BarLock,
} from "react-icons/md";

type WifiSvgConnectionQualityProps = {
  quality: number;
};

const WifiSvgConnectionQualityWithPassword: FC<WifiSvgConnectionQualityProps> = ({
  quality,
}) => {
  if (quality >= -50) {
    return <MdOutlineSignalWifi4BarLock color="blue" />;
  } else if (quality < -50 && quality > -60) {
    return <MdSignalWifi3BarLock color="blue" />;
  } else if (quality <= -60 && quality > -70) {
    return <MdSignalWifi2BarLock color="blue" />;
  } else if (quality <= -70) {
    return <MdSignalWifi1BarLock color="blue" />;
  } else {
    return null;
  }
};

export { WifiSvgConnectionQualityWithPassword };
