import { FC } from "react";
import { Flex, Tab, TabPanel, Text } from "@chakra-ui/react";
import { WifiBox } from "../WifiBox";
import { useDeviceDispatch, useDeviceState } from "hooks";
import { getWifiList } from "service/btService";
import { createSerialProcessor } from "utils";
type WifiScanningProps = {};

const WifiScanningContainer: FC<WifiScanningProps> = () => {
  return (
    <Flex
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Text fontSize="4xl">Wifi</Text>
      <WifiBox disabled={true} hiddenNetwork={true} />
    </Flex>
  );
};

export const WifiScanning = {
  TabPanel: ({ ...props }) => (
    <TabPanel {...props}>
      <WifiScanningContainer />
    </TabPanel>
  ),
  TabHeader: (props: any) => {
    const { connect, data } = useDeviceState();
    const { setData, setWifiData } = useDeviceDispatch();
    const { setIsRequest } = props;
    return (
      <Tab
        w={"160px"}
        borderBottomWidth={"2px"}
        borderColor={"primary.100"}
        onClick={async () => {
          if (connect) {
            if (!data.hasOwnProperty("WIFI_CONFIG")) {
              setIsRequest(false);
            }
            const res = await createSerialProcessor(
              getWifiList.bind(null, connect),
              0
            );
            setIsRequest(true);

            setData(res);
            // @ts-ignore

            setWifiData(res.data.WIFI_LIST);
          }
        }}
      >
        Wi-Fi list
      </Tab>
    );
  },
};
