import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  colors: {
    primary: {
      100: "#e2e8f0",
      600: "#3182ce",
    },
  },
});

export { theme };
