import { FC } from "react";
import { IconEthernet } from "components";
import { LAN_ENUM } from "service/Service";

type EthernetSvgConnectionProps = {
  type: LAN_ENUM;
};

const EthernetSvgConnection: FC<EthernetSvgConnectionProps> = ({ type }) => {
  switch (type) {
    case LAN_ENUM.WIFI_CONNECTED:
      return <IconEthernet stroke="gray" size="30px" />;
    case LAN_ENUM.WIFI_NOT_CONNECTED:
      return <IconEthernet stroke="gray" size="30px" />;
    case LAN_ENUM.LAN_CONNECTED:
      return <IconEthernet stroke="blue" size="30px" />;
    case LAN_ENUM.LAN_FAIL_CONNECTION:
      return <IconEthernet stroke="gray" size="30px" />;
    default:
      return null;
  }
};

export { EthernetSvgConnection };
