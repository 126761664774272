import React, { FC, PropsWithChildren } from "react";

type HeaderComponentProps = PropsWithChildren<{
	className?: string;
}>;

const Header: FC<HeaderComponentProps> = ({ children, className }) => {
	return <header className={className}>{children}</header>;
};

export { Header };
