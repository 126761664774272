import { WifiDataTypes } from "utils/getWifiData";

export type DeviceData = {
  UNIT_ID?: string;
  SOFTWARE_VERSION?: string;
  HARDWARE_VERSION?: string;
  DEVICE_INFO?: string;
  ERROR_REPORT?: string;
  [key: string]: any;
};

export type Device = {
  name: string;
  data: DeviceData;
};

export type StateDevice = {
  debugMode: boolean;
  data: any;
  isConnected: boolean;
  isConnectBluetooth: boolean;
  connect?: BluetoothRemoteGATTServer;
  device?: BluetoothDevice;
  wifiStatus: {
    message: string;
    status: number;
  };
  wifi: WifiDataTypes;
  wifiData: WifiDataTypes[];
  deviceStatus: number;
  configDevice?: any;
  oldConfig?: any;
  tabIndex: number;
};

export enum DeviceActionType {
  setIsConnected,
  setConfigDevice,
  setData,
  setWifiConnectionStatus,
  setWifiData,
  setDebugMode,
  setDataConnect,
  resetDevice,
  setWifiDataIsConnect,
  setStatusDevice,
  changeConfig,
  setTabIndex,
  setIsConnectBluetooth,
}

export type DeviceActions =
  | {
      readonly type: DeviceActionType.setIsConnected;
      readonly payload: boolean;
    }
  | {
      readonly type: DeviceActionType.setConfigDevice;
      readonly payload: any;
    }
  | {
      readonly type: DeviceActionType.setData;
      readonly payload: any;
    }
  | {
      readonly type: DeviceActionType.setWifiConnectionStatus;
      readonly payload: {
        message: string;
        status: number;
      };
    }
  | {
      readonly type: DeviceActionType.setWifiData;
      readonly payload: WifiDataTypes[];
    }
  | {
      readonly type: DeviceActionType.setDebugMode;
      readonly payload: boolean;
    }
  | {
      readonly type: DeviceActionType.setDataConnect;
      readonly payload: BluetoothRemoteGATTServer;
    }
  | {
      readonly type: DeviceActionType.resetDevice;
      readonly payload: StateDevice;
    }
  | {
      readonly type: DeviceActionType.setWifiDataIsConnect;
      readonly payload: string;
    }
  | {
      readonly type: DeviceActionType.setStatusDevice;
      readonly payload: number;
    }
  | {
      readonly type: DeviceActionType.changeConfig;
      readonly payload: any;
    }
  | {
      readonly type: DeviceActionType.setTabIndex;
      readonly payload: number;
    }
  | {
      readonly type: DeviceActionType.setIsConnectBluetooth;
      readonly payload: boolean;
    };
