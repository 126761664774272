import { FC } from "react";
import { TabList } from "@chakra-ui/react";
import {
  UnitConnectivityState,
  UnitInfo,
  WifiConfig,
  WifiScanning,
  ErrorReport,
  AdministratorAccess,
  SoftwareUpdate,
  ChangeConfiguration,
  SettingsUpdate
} from "../componentsTabs";

type TabHeaderProps = {
  setIsRequest: (value: boolean) => void;
};

const TabHeader: FC<TabHeaderProps> = ({ setIsRequest }) => {
  return (
    <TabList flexWrap={"wrap"} justifyContent={"center"}>
      <WifiConfig.TabHeader setIsRequest={setIsRequest} />
      <UnitConnectivityState.TabHeader setIsRequest={setIsRequest} />
      <WifiScanning.TabHeader setIsRequest={setIsRequest} />
      <UnitInfo.TabHeader setIsRequest={setIsRequest} />
      <ErrorReport.TabHeader setIsRequest={setIsRequest} />
      <ChangeConfiguration.TabHeader />
      <SettingsUpdate.TabHeader/>
      <SoftwareUpdate.TabHeader setIsRequest={setIsRequest} />
      <AdministratorAccess.TabHeader setIsRequest={setIsRequest} />
    </TabList>
  );
};

export { TabHeader };
