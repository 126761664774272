import { useEffect } from "react";
import { FaBluetooth } from "react-icons/fa";
import { useDeviceState, useDeviceDispatch } from "hooks";
import {
  WifiGlobalSvg,
  EthernetSvgConnection,
  WifiSvgConnectionQuality,
} from "assets/icons/global";
import { getLanStatus } from "service/btService";
import {
  getServiceData,
  unitConnectivityService,
  WIFI_ENUM,
} from "service/Service";
import { createSerialProcessor } from "utils";

const WifiIcon = () => {
  const {
    wifi,
    wifiStatus: { status },
  } = useDeviceState();
  return (
    <>
      {status === WIFI_ENUM.WIFI_CONNECTED ? (
        <WifiSvgConnectionQuality size={"30px"} quality={wifi.connection} />
      ) : (
        <WifiGlobalSvg type={status} />
      )}
    </>
  );
};

export const Indicators = () => {
  const { data, connect, isConnectBluetooth } = useDeviceState();
  const { setIsConnectBluetooth } = useDeviceDispatch();
  const { UNIT_LAN_CONNECTION_STATUS } = getServiceData(
    data,
    unitConnectivityService.name
  );

  useEffect(() => {
    if (connect) {
      setInterval(async () => {
        try {
          await createSerialProcessor(getLanStatus.bind(null, connect), 0);
          setIsConnectBluetooth(true);
        } catch (e) {
          console.log("Indicators error", e);
          setIsConnectBluetooth(false);
        }
      }, 5000);
    }
  }, [connect]);
  return (
    <div className="indicators">
      <div className="bluetooth-icon">
        <FaBluetooth
          color={isConnectBluetooth ? "blue" : "grey"}
          size="30px"
        />
      </div>
      <div className="wifi-icon">
        <WifiIcon />
      </div>
      <div>
        <EthernetSvgConnection type={UNIT_LAN_CONNECTION_STATUS} />
      </div>
    </div>
  );
};
