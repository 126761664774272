import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { App } from "./App";
import { DeviceContextProvider } from "./state/Device";
import { theme } from "./data/customStyles";

(() => {
  const container = document.getElementById("root");
  if (!container) {
    return null;
  }
  const root = ReactDOM.createRoot(container);

  root.render(
    <ChakraProvider theme={theme}>
      <DeviceContextProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </DeviceContextProvider>
    </ChakraProvider>
  );
})();
