import { createContext } from "react";
import { DeviceData } from "state/Device/device";

const DeviceDispatch = createContext({
  setIsConnected: (isConnected: boolean) => {},
  setConfigDevice: (device: any) => {},
  setData: (data: DeviceData) => {},
  setWifiConnectionStatus: (status: number) => {},
  setWifiData: (string: string) => {},
  setDebugMode: (value: boolean) => {},
  setIsConnectBluetooth: (value: boolean) => {},
  setDataConnect: (connect: BluetoothRemoteGATTServer) => {},
  resetDevice: () => {},
  setWifiDataIsConnect: (value: string) => {},
  setStatusDevice: (value: number) => {},
  changeConfig: (value: any) => {},
  setTabIndex: (index: number) => {},
});

export { DeviceDispatch };
