import {FC, useState} from "react";
import {Tab, TabPanel, Button, Text} from "@chakra-ui/react";
import { useDeviceState } from "hooks";
import {uploadSettingsConfigToUnit} from "utils/api/uploadSettingsConfigToUnit";

type SettingsUpdateProps = {};

const SoftwareUpdateText: FC<{ text: string }> = ({ text }) => {
  return (
      <Text fontWeight={"bold"}>{text}</Text>
  );
};

const SettingsUpdateContainer: FC<SettingsUpdateProps> = () => {
  const { connect, data } = useDeviceState();
  const { UNIT_ID, configDevice } = data;
  const [updateStatus, setUpdateStatus] = useState("");
  
  const settingsUpdate = () => {
    if (connect) {
      (async () => {
        setUpdateStatus("Updating...");
         try {
           await uploadSettingsConfigToUnit(connect, UNIT_ID, configDevice);
           setUpdateStatus("Successfully Updated");
         } catch (e) {
           console.log(e);
           setUpdateStatus("Error, please try again later");
        }
      })();
    }
  };
  return (
    <div>
      <SoftwareUpdateText text={updateStatus} />
      <Button
          onClick={settingsUpdate}
          colorScheme={"telegram"}
      >
        Settings update
      </Button>
    </div>
  );
};

const SettingsUpdate = {
  TabPanel: ({ ...props }) => (
    <TabPanel {...props}>
      <SettingsUpdateContainer />
    </TabPanel>
  ),
  TabHeader: ({ ...props }) => {
    return (
      <Tab>
        Settings update
      </Tab>
    );
  },
};

export { SettingsUpdate };
