import { FC } from "react";
import { TabPanels, Box } from "@chakra-ui/react";
import {
  UnitConnectivityState,
  UnitInfo,
  WifiConfig,
  WifiScanning,
  ErrorReport,
  AdministratorAccess,
  SoftwareUpdate,
  ChangeConfiguration,
  SettingsUpdate,
} from "../componentsTabs";
import { IconOval } from "components";

type TabBodyProps = {
  isRequest?: boolean;
  setIsRequest: (value: boolean) => void;
};

const ContentsTabBody: FC<TabBodyProps> = ({ setIsRequest }): JSX.Element => {
  return (
    <TabPanels>
      <WifiConfig.TabPanel />
      <UnitConnectivityState.TabPanel setIsRequest={setIsRequest} />
      <WifiScanning.TabPanel />
      <UnitInfo.TabPanel />
      <ErrorReport.TabPanel />
      <ChangeConfiguration.TabPanel />
      <SettingsUpdate.TabPanel />
      <SoftwareUpdate.TabPanel />
      <AdministratorAccess.TabPanel />
    </TabPanels>
  );
};

const TabBody: FC<TabBodyProps> = ({ isRequest, setIsRequest }) => {
  return (
    <Box textAlign={"center"} maxW={"80%"} margin={"auto"}>
      {isRequest ? (
        <ContentsTabBody setIsRequest={setIsRequest} />
      ) : (
        <IconOval color="gray" width="100" height="100" margin="30px" />
      )}
    </Box>
  );
};

export { TabBody };
