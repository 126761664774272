import { DeviceData } from "state/Device/device";

interface BaseInterface {
  name: string;
  UUID: BluetoothServiceUUID;
}

interface CharacteristicInterface extends BaseInterface {
  readValue: (characteristic: any) => void;
}

interface ServiceInterface extends BaseInterface {
  characteristic: Characteristic[];
}

export enum CharacteristicType {
  readOnly,
  writeOnly,
}

export enum CharacteristicFormat {
  integer,
  string,
}

export class Characteristic implements CharacteristicInterface {
  mock: Uint8Array | DataView | undefined;
  type: CharacteristicType;
  format: CharacteristicFormat;

  constructor(
    public name: string,
    public UUID: BluetoothServiceUUID,
    mock: string | number = "",
    type = CharacteristicType.readOnly,
    format = CharacteristicFormat.string
  ) {
    this.name = name;
    this.UUID = UUID;
    this.type = type;

    if (format === CharacteristicFormat.integer) {
      const buffer = new ArrayBuffer(16);
      const view = new DataView(buffer);
      if (typeof mock === "number") {
        view.setInt8(0, mock);
        this.mock = view;
      }
    } else {
      let encoder = new TextEncoder();
      if (typeof mock === "string") {
        this.mock = encoder.encode(mock);
      }
    }
    this.format = format;
  }

  readValue = async (
    characteristic: BluetoothRemoteGATTCharacteristic
  ): Promise<any> => {
    if (characteristic === null) {
      console.log(`${this.name}: Unknown sensor location.`);
      return Promise.resolve();
    }
    const unitInfoData = await characteristic.readValue();
    let value;
    if (this.format === CharacteristicFormat.integer) {
      value = unitInfoData.getInt8(0);
    } else {
      value = new TextDecoder().decode(unitInfoData);
    }
    console.log("READ:", this.name, value);
    return { [this.name]: value };
  };
}

type Filter<Type> = {
  [Property in keyof Type]?: Type[Property];
};

export class Service implements ServiceInterface {
  characteristic: Characteristic[] = [];
  constructor(
    public name: string,
    public UUID: BluetoothServiceUUID,
    characteristic: Characteristic[]
  ) {
    this.name = name;
    this.UUID = UUID;

    this.bulkAddCharacteristic(...characteristic);
  }

  getChar(name: string): Characteristic {
    const char = this.characteristic.find((c) => c.name === name);
    if (!char) {
      throw new Error(`${this.name}: ${name} not found!!`);
    }
    return char;
  }

  public getCharacteristic(filter?: Filter<Characteristic>) {
    if (filter) {
      let data = this.characteristic;
      Object.entries(filter).map(([key, value]) => {
        data = data.filter(
          (c) => c[key as keyof Filter<Characteristic>] === value
        );
      });
      return data;
    }
    return this.characteristic;
  }

  private bulkAddCharacteristic(...characteristic: Characteristic[]) {
    this.characteristic = [...this.characteristic, ...characteristic];
  }
}

export const deviceInfoService = new Service(
  "DEVICE_INFO",
  "0000180a-0000-1000-8000-00805f9b34fb",
  [
    new Characteristic("UNIT_ID", "00002a25-1000-1000-8000-00805f9b34fb", ""),
    new Characteristic(
      "SOFTWARE_VERSION",
      "00002a28-0000-1000-8000-00805f9b34fb",
      "0"
    ),
    new Characteristic(
      "HARDWARE_VERSION",
      "00002a27-0000-1000-8000-00805f9b34fb",
      "05"
    ),
    new Characteristic(
      "DEVICE_INFO",
      "00002a27-0001-1000-8000-00805f9b34fb",
      "[INFO] ESP unit details: SDK Version : Flash Chip Size : Flash Chip Speed : Free Heap\n" +
        "[INFO] \t\t\t\t  v3.2.3-14-gd3e562907 : 4194304 : 40000000 : 214300 [-time is unknown-]UTC - [INFO] Sensors in this system: WiFi RSSI CO2_Sen tVOC_Sen Temp_Sen Humi_Sen"
    ),
  ]
)

export const unitId = new Service(
  "UNIT_ID",
  "0000180a-0000-1000-8000-00805f9b34fb",
  [
    new Characteristic("UNIT_ID", "00002a25-1000-1000-8000-00805f9b34fb", ""),
  ]
);
export const hardwareVersion = new Service(
  "HARDWARE_VERSION",
  "0000180a-0000-1000-8000-00805f9b34fb",
  [
    new Characteristic(
      "HARDWARE_VERSION",
      "00002a27-0000-1000-8000-00805f9b34fb",
      "05"
    ),
  ]
);

export const softwareVersion = new Service(
  "SOFTWARE_VERSION",
  "0000180a-0000-1000-8000-00805f9b34fb",
  [
    new Characteristic(
      "SOFTWARE_VERSION",
      "00002a28-0000-1000-8000-00805f9b34fb",
      "0"
    ),
  ]
);

export const deviceInfo = new Service(
  "DEVICE_INFO",
  "0000180a-0000-1000-8000-00805f9b34fb",
  [
    new Characteristic(
      "DEVICE_INFO",
      "00002a27-0001-1000-8000-00805f9b34fb",
      "[INFO] ESP unit details: SDK Version : Flash Chip Size : Flash Chip Speed : Free Heap\n" +
        "[INFO] \t\t\t\t  v3.2.3-14-gd3e562907 : 4194304 : 40000000 : 214300 [-time is unknown-]UTC - [INFO] Sensors in this system: WiFi RSSI CO2_Sen tVOC_Sen Temp_Sen Humi_Sen"
    ),
  ]
);

export const deviceInfoError = new Service(
  "ERROR_REPORT",
  "0000180a-0000-1000-8000-00805f9b34fb",
  [
    new Characteristic(
      "ERROR_REPORT",
      "00002a4d-0000-1000-8000-00805f9b34fb",
      "[ERROR] Connect with WiFi Fallback credetials:SSID [RadGreenIoT] Pass [agm9r2XsMNiS8r7LjperP5iWfCwCIRQCxtf9crmsloM=] failed, trying to connect with Fallback WiFi credetials."
    ),
  ]
);

export enum WIFI_ENUM {
  WIFI_NOT_CONNECTED,
  WIFI_CONN_IN_PROGRESS,
  WIFI_FAIL_CONNECTION,
  WIFI_CONNECTED,
}
export enum LAN_ENUM {
  WIFI_CONNECTED,
  WIFI_NOT_CONNECTED,
  LAN_CONNECTED,
  LAN_FAIL_CONNECTION,
}

export const wifiConfigService = new Service(
  "WIFI_CONFIG",
  "b14cef7a-433f-4a25-8d4a-167a62c229a8",
  [
    new Characteristic(
      "WIFI_LIST",
      "b14cef8a-433f-4a25-8d4a-167a62c229a8",
      "apricot:-47:*|test wifi:-70:|Hidden Network:-48:*|",
      CharacteristicType.readOnly
    ),
    new Characteristic(
      "WIFI_SSID",
      "b14cef9a-433f-4a25-8d4a-167a62c229a8",
      "",
      CharacteristicType.writeOnly
    ),
    new Characteristic(
      "WIFI_PASSWORD",
      "b14cefaa-433f-4a25-8d4a-167a62c229a8",
      "",
      CharacteristicType.writeOnly
    ),
  ]
);
export const wifiConfigStatus = new Service(
  "WIFI_CONFIG",
  "b14cef7a-433f-4a25-8d4a-167a62c229a8",
  [
    new Characteristic(
      "WIFI_CONNECTION_STATUS",
      "b14cefba-433f-4a25-8d4a-167a62c229a8",
      WIFI_ENUM.WIFI_NOT_CONNECTED,
      CharacteristicType.readOnly,
      CharacteristicFormat.integer
    ),
  ]
);

export const unitControlService = new Service(
  "UNIT_CONTROL_SERVICE",
  "834bfebd-bb18-432a-9d30-3284b31ec319",
  [
    new Characteristic(
      "UNIT_RESET_STATUS",
      "834bfecd-bb18-432a-9d30-3284b31ec319",
      "",
      CharacteristicType.writeOnly
    ),
  ]
);

export const unitConnectivityService = new Service(
  "Unit Connectivity",
  "514ccc38-4f64-4da4-af6e-ab14d2976f5d",
  [
    new Characteristic(
      "UNIT_LAN_CONNECTION_STATUS",
      "514ccc48-4f64-4da4-af6e-ab14d2976f5d",
      1,
      CharacteristicType.readOnly,
      CharacteristicFormat.integer
    ),
    new Characteristic(
      "UNIT_INTER_CONNECTION_STATUS",
      "514ccc58-4f64-4da4-af6e-ab14d2976f5d",
      1,
      CharacteristicType.readOnly,
      CharacteristicFormat.integer
    ),
    new Characteristic(
      "UNIT_NTP_SERVER_CONNECTION_STATUS",
      "514ccc68-4f64-4da4-af6e-ab14d2976f5d",
      1,
      CharacteristicType.readOnly,
      CharacteristicFormat.integer
    ),
    new Characteristic(
      "UNIT_CENTRAL_SERVER_CONNECTION_STATUS",
      "514ccc78-4f64-4da4-af6e-ab14d2976f5d",
      1,
      CharacteristicType.readOnly,
      CharacteristicFormat.integer
    ),
    new Characteristic(
      "UNIT_CONFIG_SERVER_CONNECTION_STATUS",
      "514ccc88-4f64-4da4-af6e-ab14d2976f5d",
      1,
      CharacteristicType.readOnly,
      CharacteristicFormat.integer
    ),
  ]
);

export const unitConfigService = new Service(
  "UNIT_CONFIG_SERVICE",
  "eb87f6a2-1570-4b48-b939-478b75cac8ea",
  [
    new Characteristic(
      "UNIT_SOFT_UPDATE_STATUS",
      "eb87f6b2-1570-4b48-b939-478b75cac8ea",
      1,
      CharacteristicType.readOnly,
      CharacteristicFormat.integer
    ),
    new Characteristic(
      "UNIT_CONFIG_UPDATE_STATUS",
      "eb87f6c2-1570-4b48-b939-478b75cac8ea",
      1,
      CharacteristicType.readOnly,
      CharacteristicFormat.integer
    ),
    new Characteristic(
      "UNIT_SETT_UPDATE_STATUS",
      "eb87f6d2-1570-4b48-b939-478b75cac8ea",
      1,
      CharacteristicType.readOnly,
      CharacteristicFormat.integer
    ),
  ]
);

export enum ConnectionStatus {
  inProgress,
  established,
  failed,
}

export enum DEVICE_STATUS {
  CONFIG_FILE_CHANGED = 1,
  SETTINGS_FILE_UPDATED = 2,
  NO_ACTIONS_TAKEN = 7,
}

export enum FILE_UPDATED {
  APP_FETCH_FILE_FROM_SERVER,
  FILE_LOADING_TO_DEVICE,
  FILE_LOADED_TOP_DEVICE_SUCCESSFULLY
}

export const getServiceData = (data: DeviceData, serviceName: string) => {
  return data[serviceName] || {};
};
