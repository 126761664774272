import React, { FC } from "react";
import { Routes, Route } from "react-router-dom";
import { DevicePage, ConnectPage } from "../../pages";
import { RequireConnect } from "../../hoc";

type RouterConnectProps = {};

const RouterConnect: FC<RouterConnectProps> = () => {
	return (
		<Routes>
			<Route path="/" element={<ConnectPage />} />
			<Route
				path="/connect"
				element={
					<RequireConnect>
						<DevicePage />
					</RequireConnect>
				}
			/>
		</Routes>
	);
};

export { RouterConnect };
