import React, { FC } from "react";

type IconEthernetProps = {
  stroke?: string;
  size: string;
};

const IconEthernet: FC<IconEthernetProps> = ({ stroke = "gray", size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill={"transparent"}
        stroke={stroke}
        strokeLinejoin="round"
        d="M1 2a1 1 0 011-1h15.973a1 1 0 011 1v16a1 1 0 01-1 1H2a1 1 0 01-1-1V2z"
      ></path>
      <mask id="path-2-inside-1_102_69" fill={"#fff"}>
        <path
          fillRule="evenodd"
          d="M8.045 3.273a.5.5 0 00-.5.5v2.772H5.941a.5.5 0 00-.5.5V9.08H3.136a.5.5 0 00-.5.5v6.285a.5.5 0 00.5.5h13.728a.5.5 0 00.5-.5V9.579a.5.5 0 00-.5-.5h-2.306V7.045a.5.5 0 00-.5-.5h-1.604V3.773a.5.5 0 00-.5-.5H8.046z"
          clipRule="evenodd"
        ></path>
      </mask>
      <path
        fill="transparent"
        fillRule="evenodd"
        d="M8.045 3.273a.5.5 0 00-.5.5v2.772H5.941a.5.5 0 00-.5.5V9.08H3.136a.5.5 0 00-.5.5v6.285a.5.5 0 00.5.5h13.728a.5.5 0 00.5-.5V9.579a.5.5 0 00-.5-.5h-2.306V7.045a.5.5 0 00-.5-.5h-1.604V3.773a.5.5 0 00-.5-.5H8.046z"
        clipRule="evenodd"
      ></path>
      <path
        fill={stroke}
        d="M7.545 6.545v1h1v-1h-1zM5.441 9.08v1h1v-1h-1zm9.117 0h-1v1h1v-1zm-2.104-2.534h-1v1h1v-1zM8.546 3.773a.5.5 0 01-.5.5v-2a1.5 1.5 0 00-1.5 1.5h2zm0 2.772V3.773h-2v2.772h2zm-1-1H5.941v2h1.604v-2zm-1.604 0a1.5 1.5 0 00-1.5 1.5h2a.5.5 0 01-.5.5v-2zm-1.5 1.5V9.08h2V7.045h-2zm1 1.034H3.136v2h2.305v-2zm-2.305 0a1.5 1.5 0 00-1.5 1.5h2a.5.5 0 01-.5.5v-2zm-1.5 1.5v6.285h2V9.579h-2zm0 6.285a1.5 1.5 0 001.5 1.5v-2a.5.5 0 01.5.5h-2zm1.5 1.5h13.728v-2H3.136v2zm13.728 0a1.5 1.5 0 001.5-1.5h-2a.5.5 0 01.5-.5v2zm1.5-1.5V9.579h-2v6.285h2zm0-6.285a1.5 1.5 0 00-1.5-1.5v2a.5.5 0 01-.5-.5h2zm-1.5-1.5h-2.306v2h2.306v-2zm-1.306 1V7.045h-2V9.08h2zm0-2.034a1.5 1.5 0 00-1.5-1.5v2a.5.5 0 01-.5-.5h2zm-1.5-1.5h-1.604v2h1.604v-2zm-2.604-1.772v2.772h2V3.773h-2zm.5.5a.5.5 0 01-.5-.5h2a1.5 1.5 0 00-1.5-1.5v2zm-3.909 0h3.91v-2h-3.91v2z"
        mask="url(#path-2-inside-1_102_69)"
      ></path>
      <path stroke={stroke} strokeLinecap="round" d="M12 14v1.5"></path>
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeOpacity="0.2"
        d="M12 14v1.5"
      ></path>
      <path stroke={stroke} strokeLinecap="round" d="M8 14v1.5"></path>
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeOpacity="0.2"
        d="M8 14v1.5"
      ></path>
      <path stroke={stroke} strokeLinecap="round" d="M6 14v1.5"></path>
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeOpacity="0.2"
        d="M6 14v1.5"
      ></path>
      <path stroke={stroke} strokeLinecap="round" d="M14 14v1.5"></path>
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeOpacity="0.2"
        d="M14 14v1.5"
      ></path>
      <path stroke={stroke} strokeLinecap="round" d="M10 14v1.5"></path>
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeOpacity="0.2"
        d="M10 14v1.5"
      ></path>
    </svg>
  );
};

export { IconEthernet };
