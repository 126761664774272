import { FC } from "react";
import { BiWifi, BiWifiOff } from "react-icons/bi";
import { WIFI_ENUM } from "service/Service";
type WifiGlobalSvgProps = {
	type: WIFI_ENUM;
};

const WifiGlobalSvg: FC<WifiGlobalSvgProps> = ({ type }) => {
	switch (type) {
		case WIFI_ENUM.WIFI_NOT_CONNECTED:
			return <BiWifi color="gray" size="30px" />;
		case WIFI_ENUM.WIFI_CONN_IN_PROGRESS:
			return <BiWifi color="blue" size="30px" />;
		case WIFI_ENUM.WIFI_FAIL_CONNECTION:
			return <BiWifiOff color="gray" size="30px" />;
		case WIFI_ENUM.WIFI_CONNECTED:
			return <BiWifi color="blue" size="30px" />;
		default:
			return <BiWifi color="gray" size="30px" />;
	}
};

export { WifiGlobalSvg };
