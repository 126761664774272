import React, { FC, PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";
import { useDeviceState } from "../hooks";

type RequireConnectProps = PropsWithChildren<{}>;

const RequireConnect: FC<RequireConnectProps> = ({ children }): any => {
	const { isConnected } = useDeviceState();
    
	if (!isConnected) {
		return <Navigate to="/" />;
	}
	return children;
};

export { RequireConnect };
