import { FC, useEffect, useState } from "react";
import { Tabs, Box, Flex } from "@chakra-ui/react";
import { TabBody, TabHeader, HeaderDevicePage } from "./componentsDevicePage";
import {btService, getLanStatus, writeToConfig, putUnitConfig} from "service/btService";
import { useDeviceDispatch, useDeviceState } from "hooks";
import { createSerialProcessor, getConfigDevice } from "utils";

type DevicePageProps = {};

const DevicePage: FC<DevicePageProps> = () => {
  const { setIsConnected, setData, resetDevice, setTabIndex, setIsConnectBluetooth } = useDeviceDispatch();
  const { data, connect, device, configDevice, oldConfig, tabIndex } = useDeviceState();
  const [isRequest, setIsRequest] = useState(true);
  const { UNIT_ID } = data;

  const handleDisconnection = async () => {
    if (connect) {
      const config = await getConfigDevice(configDevice, connect);
      const { sampling, sending } = config["general"];
      await putUnitConfig(sampling, UNIT_ID, "general.sampling");
      await putUnitConfig(sending, UNIT_ID, "general.sending");
      if (JSON.stringify(config) !== JSON.stringify(oldConfig) && configDevice) {
        await writeToConfig(connect, config);
      }
      btService.disconnect(device);
      setIsConnected(false);
      setIsConnectBluetooth(false);
      resetDevice();
    }
  };

  const handleTabsChange = (index:number) => {
    setTabIndex(index)
  }

  useEffect(() => {
    if (connect) {
      (async ()=>{
        const res = await createSerialProcessor(getLanStatus.bind(null, connect), 0)
        setData(res);
      })()
    }
  }, [connect]);


  return (
    <>
      <HeaderDevicePage
        deviceId={UNIT_ID}
        handleDisconnection={handleDisconnection}
      />
      {/*<button onClick={() => {*/}
      {/*  if (connect)*/}
      {/*    readUnitConfig(connect).then((res) => {*/}
      {/*      console.log(res);*/}
      {/*    })*/}
      {/*}}>Read</button>*/}
      <Flex
        px={"10px"}
        justifyContent={"center"}
        alignContent={"center"}
        flexWrap={"wrap"}
        gap={"10"}
      >
        <Box w={"100%"} maxW="100%">
          <Tabs index={tabIndex} onChange={handleTabsChange} isLazy>
            <TabHeader setIsRequest={setIsRequest} />
            <TabBody isRequest={isRequest} setIsRequest={setIsRequest} />
          </Tabs>
        </Box>
      </Flex>
    </>
  );
};

export { DevicePage };
