import {useDeviceState} from "hooks/useDeviceState";
import {ConnectionStatus, getServiceData, unitConnectivityService} from "service/Service";

export const useIsConnected = () => {
    const {connect, data} = useDeviceState();
    if (!connect) {
        return null;
    }
    const serviceData = getServiceData(data, unitConnectivityService.name);
    const {UNIT_INTER_CONNECTION_STATUS, UNIT_CONFIG_SERVER_CONNECTION_STATUS} =
        serviceData;
    return UNIT_INTER_CONNECTION_STATUS === ConnectionStatus.established &&
        UNIT_CONFIG_SERVER_CONNECTION_STATUS === ConnectionStatus.established;
}