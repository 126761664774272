import { getIsCloseWifi } from "./getIsCloseWifi";

const getIsValidInputPassword = (
  data: any,
  value: string,
  password: string
) => {
  if (getIsCloseWifi(data, value)) {
    return !!password;
  }
  return true;
};

export { getIsValidInputPassword };
