import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Flex, Text, Box, useDisclosure } from "@chakra-ui/react";
import { Indicators, Header, ModalInfo } from "components";
import { resetUnit } from "service/btService";
import { useDeviceState, useDeviceDispatch } from "hooks";

type HeaderDevicePageProps = {
  handleDisconnection: () => void;
  deviceId: string;
};

const HeaderDevicePage: FC<HeaderDevicePageProps> = ({
  handleDisconnection,
  deviceId,
}) => {
  const navigate = useNavigate();
  const { connect } = useDeviceState();
  const { resetDevice } = useDeviceDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onClose, onOpen } = useDisclosure();

  const handleOnClick = async () => {
    if (connect) {
      try {
        setIsLoading(true);
        await resetUnit(connect);
      } catch (e) {
        console.log("Reset Error", e);
      }
      setIsLoading(false);
      onClose();
      resetDevice();
      navigate("/", { replace: true });
    }
  };


  return (
    <>
      <Header className="header header--connect">
        <Flex gap={"10px"}>
          <Button
            onClick={handleDisconnection}
            colorScheme={"red"}
            type="button"
          >
            Disconnect
          </Button>
          <Button colorScheme={"red"} onClick={onOpen}>
            Reset
          </Button>
        </Flex>
        <Flex
          alignItems={"center"}
          justifyContent={"center"}
          flexWrap={"wrap"}
          pl={"10px"}
        >
          <Box>{deviceId && <Text>Unit Id: {deviceId}</Text>}</Box>
          <Indicators />
        </Flex>
      </Header>
      <ModalInfo
        onClose={onClose}
        activeOnClick={handleOnClick}
        isLoading={isLoading}
        isOpen={isOpen}
        textActive="Reset"
        textClose="Cancel"
        title="Warning! Unit will disconnect and perform self reset. Reset unit without saving changes? If you want to save data - just disconnect form unit"
      />
    </>
  );
};

export { HeaderDevicePage };
