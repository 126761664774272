import {getConfigServer, writeToSettingConfig } from "service/btService";
import {getConfigDevice} from "./getConfigDevice";

const uploadSettingsConfigToUnit = async (connect: BluetoothRemoteGATTServer, deviceId: string, config: any, newConfig?: any) => {
  if (newConfig) {
    await writeToSettingConfig(connect, newConfig);
    return;
  }
  const { configuration } = await getConfigDevice(config, connect);
  const address = configuration["Configuration Server DNS address"];
  const port = configuration["Configuration Server DNS Port"];
  const result = await getConfigServer(address, port, deviceId);
  await writeToSettingConfig(connect, result);
  return configuration;
};

export { uploadSettingsConfigToUnit };
