import axios from "axios";
import { encrypt } from "utils";
import { DeviceMock, WebBluetoothMock } from "../mocks/bluetoothMock";
import {
  Characteristic,
  deviceInfoError,
  wifiConfigStatus,
  CharacteristicType,
  hardwareVersion,
    softwareVersion,
    unitId,
    deviceInfoService,
  Service,
  WIFI_ENUM,
  wifiConfigService,
    deviceInfo
} from "./Service";

import logger from "../logger";
import {
    unitConfigService,
    unitConnectivityService,
    unitControlService,
} from "./Service";



const serviceList = [
  wifiConfigStatus,
  deviceInfoError,
    wifiConfigService,
    unitControlService,
    unitConnectivityService,
    unitConfigService,
    deviceInfo,
    hardwareVersion,
    softwareVersion,
    deviceInfoService,
    unitId,
];


export const btService = {
    BLEconnect,
    disconnect,
};

type bluetoothType = Bluetooth | WebBluetoothMock;
type FilterType = {[key: string]: string};

function BLEconnect(debugMode: boolean): Promise<BluetoothDevice> {

    let bluetooth: bluetoothType = window.navigator.bluetooth;
    let filterOptions:FilterType = {};
    // To test device without unit, set in .env file REACT_APP_MOCK_UNIT=true
    if (debugMode) {
        const deviceName = "RadGreen Unit 440002 MOCK";
        filterOptions = { name: deviceName };
        const device = new DeviceMock(deviceName, serviceList);
        bluetooth = new WebBluetoothMock([device]);
    }

    return requestBluetoothDevice(bluetooth, filterOptions, debugMode);
}

//function requests ble device
async function requestBluetoothDevice(
    bluetooth: bluetoothType,
    filterOptions: FilterType,
    debugMode: boolean
): Promise<BluetoothDevice> {
    let device;
    if (debugMode) {
        device = await bluetooth.requestDevice({
            filters: [
                {
                    ...filterOptions,
                    services: [deviceInfoService.UUID, wifiConfigService.UUID],
                },
            ],
        });
    } else {
        device = await bluetooth.requestDevice({
            filters: [{ services: ["device_information"] }],
            optionalServices: serviceList.map(s => s.UUID),
        });
    }
    logger.log("Connecting to device: ", device);
    return device;
}

const callMultiple = async(func: any, key: string, count: number) => {
    let results = "";
    for (let i=0; i < count; i++) {
        const res = await func;
        console.log("res", res);
        results += res[key];
    }
    return {
        [key]: results,
    }
}


export const readBuffer = async (cc: any, count: number, key: string): Promise<any> => {
    const status = async (characteristic: any): Promise<any> => {
        let buffer = "";
        let index = 0;
        for (let i of new Array(count).fill("")) {
            const data = await characteristic.readValue();
            const decoded = new TextDecoder().decode(data);
            if (decoded) {
                buffer += decoded;
            }
            index++;
        }
        return buffer
    }

    const data = await status(cc);
    return {
		[key]: data
	};
};

export const getServerCharacteristicDeviceInfo = async (
    server: BluetoothRemoteGATTServer
) => {
    
    const service = await server.getPrimaryService(deviceInfo.UUID);
    const char = deviceInfo.getChar("DEVICE_INFO")
    const cc = await service.getCharacteristic(char.UUID);
    const result = await readBuffer(cc, 4, "DEVICE_INFO");
    
    return {
        name: deviceInfo.name,
        data: result[deviceInfo.name]
    };
};

export const getServerCharacteristicError = async (
  server: BluetoothRemoteGATTServer
) => {
  const service = await server.getPrimaryService(deviceInfoError.UUID);
  const char = deviceInfoError.getChar("ERROR_REPORT")
  const cc = await service.getCharacteristic(char.UUID);
  const result = await readBuffer(cc, 4, "ERROR_REPORT");
  console.log("result", result);
  return Object.entries(result).reduce((item: any, [name, data]) => {
    item.name = name;
    item.data = {
      messenger: data,
    };
    return item;
  }, {});
};

export const getServerCharacteristicSoftwareVersion = async (
    server: BluetoothRemoteGATTServer
) => {
    const service = await server.getPrimaryService(softwareVersion.UUID);
    const result = await service
        .getCharacteristic(softwareVersion.getChar("SOFTWARE_VERSION").UUID)
        .then(softwareVersion.getChar("SOFTWARE_VERSION").readValue);
    return {
        name: softwareVersion.name,
        data: result[softwareVersion.name]
    };
};
export const getServerCharacteristicHardwareVersion = async (
  server: BluetoothRemoteGATTServer
) => {
  const service = await server.getPrimaryService(hardwareVersion.UUID);
  const result = await service
    .getCharacteristic(hardwareVersion.getChar("HARDWARE_VERSION").UUID)
    .then(hardwareVersion.getChar("HARDWARE_VERSION").readValue);
        return {
            name: hardwareVersion.name,
            data: result[hardwareVersion.name]
        };
};

export const getServerCharacteristicUnitId = async (
  server: BluetoothRemoteGATTServer
) => {
  const service = await server.getPrimaryService(unitId.UUID);
  const result = await service
    .getCharacteristic(unitId.getChar("UNIT_ID").UUID)
    .then(unitId.getChar("UNIT_ID").readValue);
        return {
            name: unitId.name,
            data: result[unitId.name]
        };
};

export const getServerCharacteristic = async (server: BluetoothRemoteGATTServer, service: Service) => {
    const primaryService = await server.getPrimaryService(service.UUID);
    const serviceCharacteristics = service.getCharacteristic({
        type: CharacteristicType.readOnly
    });
    const promises = serviceCharacteristics.map(
        (characteristic: any) => getCharacteristic(primaryService, characteristic).catch((e) => {
            console.log(`${characteristic.name}: `, e);
        })
    )
    const result = await Promise.all(promises);
    const data = result.filter(result => !(result instanceof Error));
    return {
        name: service.name,
        data: data && data.reduce((item, data) => ({
            ...item, ...data }), {}),
    };
}

const getCharacteristic = async (service: BluetoothRemoteGATTService, characteristic: any) => {
    return service.getCharacteristic(characteristic.UUID).then(characteristic.readValue);
};

export const resetUnit = async (server: BluetoothRemoteGATTServer) => {
    const service = await server.getPrimaryService(unitControlService.UUID);
    await service
        .getCharacteristic(unitControlService.getChar("UNIT_RESET_STATUS").UUID)
        .then((characteristic: { writeValue: (arg0: Uint8Array) => any; }) => {
            const encodedSsid = new TextEncoder().encode("1");
            console.log("write", unitControlService.getChar("UNIT_RESET_STATUS").UUID, encodedSsid);
            return characteristic.writeValue(encodedSsid);
        });

}

export const getWifiStatus = async (server: BluetoothRemoteGATTServer) => {
  const service = await server.getPrimaryService(wifiConfigStatus.UUID);
  const char = wifiConfigStatus.getChar("WIFI_CONNECTION_STATUS");
  const status = await service
    .getCharacteristic(char.UUID)
    .then((characteristic: any) => {
      return characteristic.readValue().then((value: any) => {
        return value.getInt8(0);
      });
    });

  return {
    name: wifiConfigStatus.name,
    data: {
      [char.name]: status,
    },
  };
};

export const getWifiList = async (server: BluetoothRemoteGATTServer) => {
  const service = await server.getPrimaryService(wifiConfigService.UUID);
  const char = wifiConfigService.getChar("WIFI_LIST");
  const status = await service
    .getCharacteristic(char.UUID)
    .then(char.readValue);

  return {
    name: wifiConfigService.name,
    data: {
      [char.name]: status[char.name],
    },
  };
};

export const getLanStatus = async (server: BluetoothRemoteGATTServer) => {
    const service = await server.getPrimaryService(unitConnectivityService.UUID);
    const char = unitConnectivityService.getChar("UNIT_LAN_CONNECTION_STATUS")
    const status = await service
        .getCharacteristic(char.UUID)
        .then((characteristic: any) => {
            return characteristic.readValue().then((value: any) => {
                return value.getInt8(0);
            })
        })
    return {
        name: unitConnectivityService.name,
        data: {
            [char.name]: status
        }
    }
}



export const setWifi = async (server: BluetoothRemoteGATTServer, ssid: any, pass: any, saveWifi?: boolean) => {
    const service = await server.getPrimaryService(wifiConfigService.UUID);
    await service
        .getCharacteristic(wifiConfigService.getChar("WIFI_SSID").UUID)
        .then((characteristic: { writeValue: (arg0: Uint8Array) => any; }) => {
            const encodedSsid = new TextEncoder().encode(ssid);
            console.log("WRITE:", wifiConfigService.getChar("WIFI_SSID").UUID, encodedSsid);
            return characteristic.writeValue(encodedSsid);
        });
    await service
        .getCharacteristic(wifiConfigService.getChar("WIFI_PASSWORD").UUID)
        .then((characteristic: { writeValue: (arg0: Uint8Array) => any; }) => {
            const encodedPass = new TextEncoder().encode(pass);
            console.log("WRITE:", wifiConfigService.getChar("WIFI_PASSWORD").UUID, encodedPass);
            return characteristic.writeValue(encodedPass);
        });
    const getWifiConnectionStatus = await service
        .getCharacteristic(wifiConfigStatus.getChar("WIFI_CONNECTION_STATUS").UUID)
        .then((characteristic: any) => {
            if (saveWifi) {
                const temp = new TextEncoder().encode("4");
                console.log("WRITE:", wifiConfigStatus.getChar("WIFI_CONNECTION_STATUS").UUID, temp);
                return characteristic.writeValue(temp);
            } else {
                return characteristic.readValue().then((value: any) => {
                    return value.getInt8(0);
                });
            }
        });
    return getWifiConnectionStatus;
};

function handleDisconnection(event: any) {
    let device = event.target;

    logger.log(
        '"' +
        device.name +
        '" bluetooth device disconnected, trying to reconnect...'
    );
}

export const writeUnitConfig = async ( server: BluetoothRemoteGATTServer, key: any ) => {
    const service = await server.getPrimaryService(unitConfigService.UUID);
     await service
        .getCharacteristic(unitConfigService.getChar("UNIT_SOFT_UPDATE_STATUS").UUID)
        .then((characteristic: any) => {
                const temp = new TextEncoder().encode(key);
                console.log("Write to UNIT_SOFT_UPDATE_STATUS", key);
                return characteristic.writeValue(temp);
            });

    }

// Disconnect from the connected device
function disconnect(device: BluetoothDevice | undefined) {
    logger.log("DISCONNECT");
    if (device) {
        try {
            logger.log(
                'Disconnecting from "' + device.name + '" bluetooth device...'
            );
            device.removeEventListener(
                "gattserverdisconnected",
                handleDisconnection
            );

            if (device?.gatt?.connected) {
                device.gatt.disconnect();
                logger.log('"' + device.name + '" bluetooth device disconnected');
            } else {
                logger.log(
                    '"' + device.name + '" bluetooth device is already disconnected'
                );
            }
        } catch (e) {
            console.log(e);
        }
    }
}


export const readUnitConfig = async (server: BluetoothRemoteGATTServer): Promise<any> => {
    console.log("readUnitConfig???");
    const service = await server.getPrimaryService(unitConfigService.UUID);
    const char = unitConfigService.getChar("UNIT_CONFIG_UPDATE_STATUS")
    const cc = await service.getCharacteristic(char.UUID);
    const status = async (characteristic: any): Promise<any> => {
        let buffer = "";
        let index = 0;
        for (let i of new Array(6).fill("")) {
            const data = await characteristic.readValue();
            const decoded = new TextDecoder().decode(data);
            if (decoded) {
                buffer += decoded;	
            } else {
                break
            }
            index++;
        }
        return buffer
    }
    
    const data = await status(cc);
    return JSON.parse(data)
};
//
// export const readUnitConfig = (server: BluetoothRemoteGATTServer): Promise<any> => {
//   return Promise.resolve(require("./440002config.json"));
// };

function chunkString (str: string, len: number) {
    const size = Math.ceil(str.length/len)
    const r = Array(size)
    let offset = 0

    for (let i = 0; i < size; i++) {
        r[i] = str.substr(offset, len)
        offset += len
    }

    return r
}

function _writeToCharacteristic(characteristic: any, data: any, index: number) {
    console.log(`chunk[${index}]:`,  data);
    return characteristic.writeValue(new TextEncoder().encode(data));
}


const send = async (_characteristic: any, data: any, ending: string=`\r\n`) => {
    try {
        const chunks = chunkString(JSON.stringify(data), 400);
        console.log("chunks", chunks.length);
        if (chunks && chunks.length > 0) {
            await _writeToCharacteristic(_characteristic, chunks[0], 0);
            for (let i = 1; i < chunks.length; i++) {
                await _writeToCharacteristic(_characteristic, chunks[i], i);
                if (i === chunks.length-1) {
                    await _writeToCharacteristic(_characteristic, ending, i);
                }
            }
        }
    } catch (e) {
        console.log("Write error:", e);
    }
     
};

export const writeToConfig = async (server: BluetoothRemoteGATTServer, payload: any, ending='\r\n'): Promise<any> => {
    const service = await server.getPrimaryService(unitConfigService.UUID);
    const char = unitConfigService.getChar("UNIT_CONFIG_UPDATE_STATUS")
    const characteristic = await service.getCharacteristic(char.UUID);
    const res = await send(characteristic, payload, ending)
    return res
};

export const writeToSettingConfig = async (server: BluetoothRemoteGATTServer, payload: any, ending='\r\n'): Promise<any> =>{
    const service = await server.getPrimaryService(unitConfigService.UUID);
    const char = unitConfigService.getChar("UNIT_SETT_UPDATE_STATUS");
    const characteristic = await service.getCharacteristic(char.UUID);
    const res = await send(characteristic, payload, ending)
    return res
    // return Promise.resolve(true)
}

export const writeToConfig2 = async (server: BluetoothRemoteGATTServer, payload: any, ending='\r\n'): Promise<any> => {
    // const service = await server.getPrimaryService(unitConfigService.UUID);
    // const char = unitConfigService.getChar("UNIT_CONFIG_UPDATE_STATUS")
    // const characteristic = await service.getCharacteristic(char.UUID);
    // const res = await send(characteristic, payload, ending)
    // return res
    return Promise.resolve(true)
};

export const putUnitConfig = async (value: string, deviceId:string, path:string) => {
  const newData = JSON.stringify({ deviceId, path, value });
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const hostUrl = "https://dev3.radgreenconfig.com:8443/setconfig";

  try {
    const data = await axios.put(hostUrl, newData, config);
      if (data.status === 200) {
        console.log('200');
        return data;
      }
      throw Error(`Couldn't get the data on URL ${hostUrl}`);
    } catch (err) {
      console.error(err);
      // throw Error(err.message);
    }


  // return result;
};

export const getConfigServer = async (
    address: string,
    port: string,
    id: string
) => {
    // const hostUrl = `https://${address}:${port}/getFile.php?filePath=conSetFiles/${id}settings.json`;
    const hostUrl = `https://${address}/getFile.php?filePath=conSetFiles/${id}settings.json`;
    try {
        const settings = await axios.get(hostUrl);
        if (settings.status === 200) {
            return settings.data;
        }
        throw Error(`Couldn't get the data on URL ${hostUrl}`);
    } catch (err) {
        console.error(err);
        // @ts-ignore
        throw Error(err);
    }
};
