export type WifiDataTypes = {
  close: boolean;
  connection: number;
  name: string;
};

const getWifiData = (wifiList: string): WifiDataTypes[] => {
  return wifiList
    .split("|")
    .reduce((item: WifiDataTypes[], wifi): WifiDataTypes[] => {
      const wifiData = wifi.split(":");
      if (wifi) {
        //todo create an object with wifi data
        const objDataWifi = {
          close: wifiData.includes("*"),
          connection: +wifiData[1],
          name: wifiData[0],
        };
        item.push(objDataWifi);
      }
      return item;
    }, []);
};

export { getWifiData };
