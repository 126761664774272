import { FC, useEffect, useState } from "react";
import { Tab, TabPanel, Text, Box } from "@chakra-ui/react";
import { useDeviceState } from "hooks";
import { uploadConfigToUnit } from "utils";

type AdministratorAccessProps = {};

const IframeContainer: FC<{ notification: string; id: string }> = ({
  notification,
  id,
}) => {
  return (
    <>
      <Box
        fontWeight={"bold"}
        position={"absolute"}
        left={"50%"}
        transform={"translate(-50%, -50%)"}
        top={"40px"}
      >
        {notification && <Text>{notification}</Text>}
      </Box>
      <Box paddingTop={"80px"}>
        <iframe
          width={"100%"}
          height={"600px"}
          src={`https://dev3.radgreenconfig.com:8443/ble-authenticate/${id}`}
          title="authenticate"
        ></iframe>
      </Box>
    </>
  );
};

const AdministratorAccessContainer: FC<AdministratorAccessProps> = () => {
  const { data, connect, isConnectBluetooth } = useDeviceState();
  const { UNIT_ID } = data;
  const [notification, setNotification] = useState("");

  useEffect(() => {
    const handler = async (event: any) => {
      try {
        const data = JSON.parse(event.data);
        console.log("Reading from iframe", data);
        if (data.type === "updateConfig" && connect) {
          try {
            await uploadConfigToUnit(connect, JSON.parse(data.data));
            setNotification("Unit config file updated");
          } catch (e) {
            setNotification("");
          }
        }
      } catch (e) {
        console.log(e);
      }
    };
    window.addEventListener("message", handler);

    return () => window.removeEventListener("message", handler);
  }, []);

  return (
    <Box position={"relative"}>
      {isConnectBluetooth ? (
        <IframeContainer notification={notification} id={UNIT_ID} />
      ) : (
        <Text fontWeight={"bold"}>No connection</Text>
      )}
    </Box>
  );
};

const AdministratorAccess = {
  TabPanel: ({ ...props }) => {
    return (
      <TabPanel {...props}>
        <AdministratorAccessContainer />
      </TabPanel>
    );
  },
  TabHeader: ({ ...props }) => {
    return (
      <Tab borderBottomWidth={"2px"} borderColor={"primary.100"}>
        Administrator access
      </Tab>
    );
  },
};

export { AdministratorAccess };
